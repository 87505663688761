import React from "react"
import { Helmet, HelmetProps } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  children?: React.ReactNode
  description?: string
  lang?: string
  meta?: HelmetProps["meta"]
  title?: string
}
function SEO({
  children,
  description = "",
  lang = "en",
  meta = [],
  title,
}: SEOProps) {
  const { site } = useStaticQuery<GatsbyTypes.DefaultSEOQuery>(
    graphql`
      query DefaultSEO {
        site {
          siteMetadata {
            author
            description
            keywords
            title
          }
        }
      }
    `
  )

  const siteMetadata = site?.siteMetadata
  const author = siteMetadata?.author
  const keywords = siteMetadata?.keywords
  const metaDescription = description || siteMetadata?.description
  const defaultMeta: HelmetProps["meta"] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `author`,
      content: author,
    },
    {
      name: `keywords`,
      content: keywords,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata?.title}`}
      meta={defaultMeta.concat(meta)}
    >
      {children}
    </Helmet>
  )
}

export default SEO
