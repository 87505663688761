import React, { useContext } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { Color } from "types"
import ColorContext from "context/ColorContext"
import colors from "style/colors"

const Root = styled(motion.li)`
  cursor: pointer;
  margin-top: ${props => props.theme.spacing1};
`

const ColorSelector = styled.div`
  border-radius: ${props => props.theme.border.radiusLg};
  width: 2rem;
  height: 2rem;
`

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const mapping: Record<Color, string> = {
  Red: colors.red500,
  Orange: colors.orange500,
  Yellow: colors.yellow500,
  Green: colors.green500,
  Teal: colors.teal500,
  Blue: colors.blue500,
  Indigo: colors.indigo500,
  Pink: colors.pink500,
}

interface ColorItemProps {
  color: Color
  onClick: () => void
}
export default function ColorItem({ color, onClick }: ColorItemProps) {
  const { setColor } = useContext(ColorContext)
  const style = { backgroundColor: mapping[color] }
  return (
    <Root
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <ColorSelector
        style={style}
        onClick={() => {
          onClick()
          setColor(color)
        }}
      />
    </Root>
  )
}
