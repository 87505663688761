import React, { useCallback, useState } from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "style/global"
import theme from "style/theme"
import { Color } from "types"
import ColorContext from "context/ColorContext"
import { lsGet, lsSet } from "utils/localStorage"

type CustomTheme = typeof theme
declare module "styled-components" {
  export interface DefaultTheme extends CustomTheme {}
}

const buildTheme = (primary: Color) => {
  const colors: any = { ...theme.colors }
  Object.keys(colors).forEach(key => {
    if (primary && key.startsWith("primary")) {
      const found = key.match(/primary(?<shade>\d+)/)
      const color =
        found &&
        found.groups &&
        colors[`${primary.valueOf().toLowerCase()}${found.groups.shade}`]
      if (color) colors[key] = color
    }
  })
  return { ...theme, colors }
}

const getColor = (): Color => {
  const color = lsGet("color")
  return (color as Color) || Color.Indigo
}

interface ProviderProps {
  children: React.ReactNode
}
const Provider = ({ children }: ProviderProps) => {
  const [color, dispatchColor] = useState<Color>(getColor)
  const setColor = useCallback((color: Color) => {
    dispatchColor(color)
    lsSet("color", color)
  }, [])

  return (
    <ColorContext.Provider
      value={{
        setColor,
      }}
    >
      <ThemeProvider theme={buildTheme(color)}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ColorContext.Provider>
  )
}

export default Provider
