import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin"
import styled from "styled-components"
import SEO from "components/SEO"
import Window from "components/Window"
import { globalHistory, navigate } from "@reach/router"
import HistoryContext from "context/HistoryContext"
import Folder from "./Folder"
import Header from "./Header"
import LinkIcon from "./LinkIcon"

const Root = styled.div`
  background-color: ${props => props.theme.colors.background};
  height: 100vh;
  overflow: scroll;
`

const Folders = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing10};
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
`

interface LayoutProps {
  children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { allArticle, allMdx, site } = useStaticQuery<
    GatsbyTypes.SiteTitleQueryQuery
  >(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          github
          instagram
          linkedin
          keywords
          title
        }
      }
      allArticle {
        nodes {
          slug
          title
        }
      }
      allMdx {
        nodes {
          frontmatter {
            title
            path
          }
        }
      }
    }
  `)

  const [count, setCount] = useState(0)
  const [windowOpen, setWindowOpen] = useState(
    () => globalHistory.location.pathname !== "/"
  )
  const [disableAnimationOnMount, setDisableAnimationOnMount] = useState(true)
  const [minimize, setMinimize] = useState(false)
  const [expand, setExpand] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)

  const [render, setRender] = useState(false)

  // HACK https://github.com/styled-components/styled-components/issues/2629
  useEffect(() => {
    const timeout = setTimeout(() => setRender(true))
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    globalHistory.listen(({ location }) => {
      setWindowOpen(location.pathname !== "/")
      if (location.pathname === "/") setCount(0)
    })
  }, [])

  const onFolderClick = () => {
    setCount(0)
    setMinimize(false)
  }

  const folders: Array<{ title: string; path: string }> = []
  allMdx.nodes.forEach(mdx => {
    if (!mdx.frontmatter?.path) return
    if (!mdx.frontmatter?.title) return
    folders.push({
      title: mdx.frontmatter.title,
      path: mdx.frontmatter.path,
    })
  })

  allArticle.nodes.forEach(article => {
    if (!article.slug) return
    if (!article.title) return

    folders.push({
      title: article.title,
      path: `/${article.slug}`,
    })
  })

  if (!render) return null
  return (
    <HistoryContext.Provider value={{ hasForward: count > 0, setCount }}>
      <SEO title={site?.siteMetadata?.title} />
      <Root ref={rootRef}>
        <Header
          keywords={site?.siteMetadata?.keywords}
          title={site?.siteMetadata?.title}
        />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <main>
            <Folders>
              {folders.map(folder => (
                <Folder
                  onClick={onFolderClick}
                  url={folder.path}
                  title={folder.title}
                />
              ))}
              {site?.siteMetadata?.github && (
                <LinkIcon icon={faGithub} url={site.siteMetadata.github} />
              )}
              {site?.siteMetadata?.instagram && (
                <LinkIcon
                  icon={faInstagram}
                  url={site.siteMetadata.instagram}
                />
              )}
              {site?.siteMetadata?.linkedin && (
                <LinkIcon icon={faLinkedin} url={site.siteMetadata.linkedin} />
              )}
            </Folders>
            <Window
              constraint={rootRef}
              disableAnimationOnMount={disableAnimationOnMount}
              isExpanded={expand}
              isMinimized={minimize}
              isOpen={windowOpen}
              onClose={() => {
                navigate("/")
                setMinimize(false)
                setDisableAnimationOnMount(true)
              }}
              onExpand={() => {
                setDisableAnimationOnMount(false)
                setExpand(e => !e)
                setMinimize(false)
              }}
              onMinimize={() => {
                setMinimize(m => !m)
                setDisableAnimationOnMount(false)
              }}
            >
              {children}
            </Window>
          </main>
        </div>
      </Root>
    </HistoryContext.Provider>
  )
}

export default Layout
