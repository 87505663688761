import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import WindowHeader from "./WindowHeader"
import WindowBody from "./WindowBody"
import WindowNav from "./WindowNav"

const Root = styled(motion.div)`
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

interface ExpandableWindowProps {
  children: React.ReactNode
  onClose: () => void
  onExpand: () => void
  onMinimize: () => void
}
const ExpandableWindow = ({
  children,
  onClose,
  onExpand,
  onMinimize,
}: ExpandableWindowProps) => {
  return (
    <Root initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
      <WindowHeader
        isExpanded
        isMinimized={false}
        onClose={onClose}
        onExpand={onExpand}
        onMinimize={onMinimize}
      />
      <WindowNav />
      <WindowBody>{children}</WindowBody>
    </Root>
  )
}

export default ExpandableWindow
