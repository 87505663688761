// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-templates-article-tsx": () => import("./../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

