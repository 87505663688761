import React, { useRef } from "react"
import styled from "styled-components"
import media from "style/media"
import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox"
import "@reach/combobox/styles.css"

const Root = styled(Combobox)`
  flex: 1 1 auto;
`

const Div = styled.div`
  border-radius: ${props => props.theme.border.radiusMd};
  box-shadow: ${props => props.theme.border.boxShadowSm};
  position: relative;
`

const Input = styled(ComboboxInput)`
  appearance: none;
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.border.color};
  border-width: 1px;
  border-radius: ${props => props.theme.border.radius};
  display: block;
  font-size: ${props => props.theme.font.size16};
  line-height: ${props => props.theme.font.leadingNormal};
  padding-top: ${props => props.theme.spacing2};
  padding-bottom: ${props => props.theme.spacing2};
  padding-left: ${props => props.theme.spacing3};
  padding-right: ${props => props.theme.spacing3};
  width: 100%;

  ${props => media.lessThan("medium")`
    line-height: ${props.theme.font.leading5};
    font-size: ${props.theme.font.size14};
  `}

  &::placeholder {
    color: ${props => props.theme.colors.neutral500};
    opacity: 1;
  }

  &:focus {
    border-color: ${props => props.theme.colors.blue400};
    box-shadow: ${props => props.theme.border.boxShadowOutline};
    outline: none;
  }
`

const Popover = styled(ComboboxPopover)`
  background-color: ${props => props.theme.colors.neutral050};
  border: 1px solid ${props => props.theme.border.color};
`
const Option = styled(ComboboxOption)`
  color: ${props => props.theme.colors.neutral800};

  [data-user-value] {
    color: ${props => props.theme.colors.primary600};
  }
  [data-suggested-value] {
    font-weight: bold;
  }

  &:hover {
    background-color: ${props => props.theme.colors.neutral200};
    color: ${props => props.theme.colors.neutral900};
  }

  &[aria-selected="true"] {
    background-color: ${props => props.theme.colors.neutral100};
  }
`

const NavSearch = () => {
  const input = useRef<HTMLInputElement>(null)
  const { allArticle, allMdx } = useStaticQuery<
    GatsbyTypes.NavSearchArticlesQuery
  >(graphql`
    query NavSearchArticles {
      allArticle {
        nodes {
          slug
          title
        }
      }
      allMdx {
        nodes {
          frontmatter {
            title
            path
          }
        }
      }
    }
  `)

  const links: Array<{ title: string; path: string }> = []

  allMdx.nodes.forEach(mdx => {
    if (!mdx.frontmatter?.path) return
    if (!mdx.frontmatter?.title) return

    links.push({
      title: mdx.frontmatter.title,
      path: mdx.frontmatter.path,
    })
  })

  allArticle.nodes.forEach(article => {
    if (!article.slug) return
    if (!article.title) return

    links.push({
      title: article.title,
      path: article.slug,
    })
  })

  return (
    <Root
      openOnFocus
      onSelect={v => {
        const link = links.find(a => a.title === v)
        if (link) navigate(link.path)
        setTimeout(() => input.current?.blur())
      }}
    >
      <Div>
        <Input ref={input} aria-labelledby="search" id="search" selectOnClick />
      </Div>
      <Popover>
        <ComboboxList aria-labelledby="search" persistSelection>
          {links.map(l => (
            <Option key={l.path} value={l.title} />
          ))}
        </ComboboxList>
      </Popover>
    </Root>
  )
}

export default NavSearch
