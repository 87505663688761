let colors = {
  primary050: "#ebf5ff",
  primary100: "#e1effe",
  primary200: "#c3ddfd",
  primary300: "#a4cafe",
  primary400: "#76a9fa",
  primary500: "#3f83f8",
  primary600: "#1c64f2",
  primary700: "#1a56db",
  primary800: "#1e429f",
  primary900: "#233876",

  neutral050: "hsl(0, 0%, 97%)",
  neutral100: "hsl(0, 0%, 88%)",
  neutral200: "hsl(0, 0%, 81%)",
  neutral300: "hsl(0, 0%, 69%)",
  neutral400: "hsl(0, 0%, 62%)",
  neutral500: "hsl(0, 0%, 49%)",
  neutral600: "hsl(0, 0%, 38%)",
  neutral700: "hsl(0, 0%, 32%)",
  neutral800: "hsl(0, 0%, 23%)",
  neutral900: "hsl(0, 0%, 13%)",

  red050: "#fdf2f2",
  red100: "#fde8e8",
  red200: "#fbd5d5",
  red300: "#f8b4b4",
  red400: "#f98080",
  red500: "#f05252",
  red600: "#e02424",
  red700: "#c81e1e",
  red800: "#9b1c1c",
  red900: "#771d1d",

  orange050: "#fff8f1",
  orange100: "#feecdc",
  orange200: "#fcd9bd",
  orange300: "#fdba8c",
  orange400: "#ff8a4c",
  orange500: "#ff5a1f",
  orange600: "#d03801",
  orange700: "#b43403",
  orange800: "#8a2c0d",
  orange900: "#771d1d",

  yellow050: "#fdfdea",
  yellow100: "#fdf6b2",
  yellow200: "#fce96a",
  yellow300: "#faca15",
  yellow400: "#e3a008",
  yellow500: "#c27803",
  yellow600: "#9f580a",
  yellow700: "#8e4b10",
  yellow800: "#723b13",
  yellow900: "#633112",

  green050: "#f3faf7",
  green100: "#def7ec",
  green200: "#bcf0da",
  green300: "#84e1bc",
  green400: "#31c48d",
  green500: "#0e9f6e",
  green600: "#057a55",
  green700: "#046c4e",
  green800: "#03543f",
  green900: "#014737",

  teal050: "#edfafa",
  teal100: "#d5f5f6",
  teal200: "#afecef",
  teal300: "#7edce2",
  teal400: "#16bdca",
  teal500: "#0694a2",
  teal600: "#047481",
  teal700: "#036672",
  teal800: "#05505c",
  teal900: "#014451",

  blue050: "#ebf5ff",
  blue100: "#e1effe",
  blue200: "#c3ddfd",
  blue300: "#a4cafe",
  blue400: "#76a9fa",
  blue500: "#3f83f8",
  blue600: "#1c64f2",
  blue700: "#1a56db",
  blue800: "#1e429f",
  blue900: "#233876",

  indigo050: "#f0f5ff",
  indigo100: "#e5edff",
  indigo200: "#cddbfe",
  indigo300: "#b4c6fc",
  indigo400: "#8da2fb",
  indigo500: "#6875f5",
  indigo600: "#5850ec",
  indigo700: "#5145cd",
  indigo800: "#42389d",
  indigo900: "#362f78",

  purple050: "#f6f5ff",
  purple100: "#edebfe",
  purple200: "#dcd7fe",
  purple300: "#cabffd",
  purple400: "#ac94fa",
  purple500: "#9061f9",
  purple600: "#7e3af2",
  purple700: "#6c2bd9",
  purple800: "#5521b5",
  purple900: "#4a1d96",

  pink050: "#fdf2f8",
  pink100: "#fce8f3",
  pink200: "#fad1e8",
  pink300: "#f8b4d9",
  pink400: "#f17eb8",
  pink500: "#e74694",
  pink600: "#d61f69",
  pink700: "#bf125d",
  pink800: "#99154b",
  pink900: "#751a3d",

  white: "#FFFFFF",
  black: "#000000",
  disabled: "",
  error: "",
  background: "#2E2E2E",
}

colors.disabled = colors.neutral300
colors.error = colors.red500

export default colors
