import React, { useEffect, useRef, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import Button from "components/Button"
import { faPaintBrush } from "@fortawesome/free-solid-svg-icons/faPaintBrush"
import ColorItem from "./ColorItem"
import colors from "./colors"

const Root = styled.div`
  flex-shrink: 0;
  position: relative;
`

const ColorItems = styled(motion.ul)`
  margin-top: ${props => props.theme.spacing2};
  position: absolute;
  top: 100%;
  z-index: 2;
`

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

export default function ColorsList() {
  const colorList = useRef<HTMLDivElement>(null)
  const [show, dispatch] = useState(false)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        colorList.current &&
        e.target instanceof Node &&
        !colorList.current.contains(e.target)
      ) {
        dispatch(false)
      }
    }

    if (typeof document !== "undefined") {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  }, [])

  const handleItemClick = () => dispatch(false)
  return (
    <Root ref={colorList}>
      <Button icon={faPaintBrush} onClick={() => dispatch(s => !s)} />
      <AnimatePresence>
        {show && (
          <ColorItems
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            {colors.map(c => (
              <ColorItem key={c} color={c} onClick={handleItemClick} />
            ))}
          </ColorItems>
        )}
      </AnimatePresence>
    </Root>
  )
}
