import React from "react"
import { AnimatePresence } from "framer-motion"
import Hidden from "components/Hidden"
import DesktopWindow, { DesktopWindowProps } from "./DesktopWindow"
import MobileWindow from "./MobileWindow"

interface WindowProps extends DesktopWindowProps {
  isOpen: boolean
}

const Window: React.FC<WindowProps> = ({
  children,
  disableAnimationOnMount,
  onClose,
  isOpen,
  ...props
}) => {
  return (
    <>
      {isOpen && (
        <Hidden mdDown>
          <DesktopWindow
            disableAnimationOnMount={disableAnimationOnMount}
            onClose={onClose}
            {...props}
          >
            {children}
          </DesktopWindow>
        </Hidden>
      )}
      <Hidden mdUp>
        <AnimatePresence>
          {isOpen && (
            <MobileWindow key="mobile-window" onClose={onClose}>
              {children}
            </MobileWindow>
          )}
        </AnimatePresence>
      </Hidden>
    </>
  )
}

export default Window
