export enum Color {
  Red = "Red",
  Orange = "Orange",
  Yellow = "Yellow",
  Green = "Green",
  Teal = "Teal",
  Blue = "Blue",
  Indigo = "Indigo",
  Pink = "Pink",
}
