import React, { useContext } from "react"
import { rgba } from "polished"
import { navigate } from "@reach/router"
import styled from "styled-components"
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight"
import Button from "components/Button"
import HistoryContext from "context/HistoryContext"
import NavSearch from "./NavSearch"

const Nav = styled.div`
  background-color: ${props => rgba(props.theme.colors.neutral700, 1)};
  flex-shrink: 0;
  padding: ${props => props.theme.spacing3} ${props => props.theme.spacing3};

  display: flex;
  align-items: center;
`

const NavButtons = styled.div`
  > * {
    margin-right: ${props => props.theme.spacing2};
  }
`

const WindowNav = () => {
  const { hasForward, setCount } = useContext(HistoryContext)
  return (
    <Nav>
      <NavButtons>
        <Button
          icon={faCaretLeft}
          size="2"
          onClick={() => {
            // window.history.back()
            navigate(-1)
            setCount(c => c + 1)
          }}
        />
        <Button
          disabled={!hasForward}
          icon={faCaretRight}
          size="2"
          onClick={() => {
            setCount(c => c - 1)

            navigate(1)
          }}
        />
      </NavButtons>
      <NavSearch />
    </Nav>
  )
}

export default WindowNav
