import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const Root = styled(Link)<{ outline: boolean }>`
  background: transparent;
  border-radius: ${props => props.theme.border.radiusSm};
  display: block;
  position: relative;
  text-align: center;
  width: 8rem;

  ${props =>
    props.outline &&
    css`
      box-shadow: ${props.theme.border.boxShadowOutline};
    `}

  &:focus {
    outline: none;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.colors.neutral050};
    text-decoration: none;
  }
`

const StyledFolder = styled.div`
  box-shadow: ${props => props.theme.border.boxShadow2xl};
  height: 6rem;
  position: relative;
`

const BackHandle = styled.div`
  background-color: ${props => props.theme.colors.neutral700};
  border-top-left-radius: 0.5rem;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 45%, 75% 0);
  position: absolute;
  height: ${props => props.theme.spacing6};
  width: 40%;
`

const Back = styled.div`
  border-top-right-radius: 0.5rem;
  background-color: ${props => props.theme.colors.neutral700};
  height: ${props => props.theme.spacing4};
  width: 100%;
  position: absolute;
  top: ${props => props.theme.spacing2};
`

const Front = styled.div`
  background-color: ${props => props.theme.colors.neutral600};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  top: ${props => props.theme.spacing4};
  bottom: 0;
  position: absolute;
`

const Title = styled.div`
  padding-top: ${props => props.theme.spacing2};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

interface FolderProps {
  onClick: () => void
  title: string
  url: string
}
const Folder = ({ onClick, title, url }: FolderProps) => {
  const [outline, setOutline] = useState(false)
  const folder = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        folder.current &&
        e.target instanceof Node &&
        !folder.current.contains(e.target)
      ) {
        setOutline(false)
        return
      }
    }
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  return (
    <Root
      innerRef={folder}
      to={url}
      onClick={() => {
        onClick()
        setOutline(true)
      }}
      outline={outline}
    >
      <StyledFolder>
        <BackHandle />
        <Back />
        <Front />
      </StyledFolder>
      <Title>{title}</Title>
    </Root>
  )
}

export default Folder
