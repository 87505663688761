import { createGlobalStyle } from "styled-components"
import media from "style/media"

export default createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  html,
  body {
    height: 100%;
  }

  body {
    background: ${props => props.theme.colors.neutral900};
    margin: 0px;
  }

  :root {
    color: ${props => props.theme.font.clrLightPrimary};
    font-family: ${props => props.theme.font.family};
    font-size: 16px;
    font-weight: 400;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;

    ${media.lessThan("medium")`
      font-size: 18px;
    `}
  }

  div#root { height: 100vh; }

  button, input {
    font-family: ${props => props.theme.font.family};
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
`
