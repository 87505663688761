import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import * as React from 'react';
export default {
  styled,
  graphql,
  useStaticQuery,
  Img,
  React
};