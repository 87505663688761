import React, { useState } from "react"
import { rgba } from "polished"
import styled, { css } from "styled-components"
import { motion, HTMLMotionProps } from "framer-motion"
import { faCompressAlt } from "@fortawesome/free-solid-svg-icons/faCompressAlt"
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons/faExpandAlt"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize"
import Dot from "components/Dot"
import { IconProps } from "components/Icon"
import spacing from "style/spacing"

type WindowHeaderSize = 1 | 2
const Header = styled(motion.div)<{
  drag?: HTMLMotionProps<"div">["drag"]
  size: WindowHeaderSize
}>`
  background-color: ${props => rgba(props.theme.colors.neutral900, 0.9)};
  flex-shrink: 0;

  ${props =>
    props.drag &&
    css`
      cursor: move;
    `}

  ${props => {
    const padding: Record<WindowHeaderSize, string> = {
      1: `padding: ${props.theme.spacing2} ${props.theme.spacing3};`,
      2: `padding: ${props.theme.spacing4} ${props.theme.spacing5};`,
    }

    return padding[props.size]
  }}
`

const Actions = styled.div`
  display: inline-flex;
  > * {
    margin-right: ${props => props.theme.spacing1};
  }

  &.hidden svg {
    visibility: hidden;
  }
`

const iconSize: Record<WindowHeaderSize, IconProps["size"]> = {
  1: "1x",
  2: "2x",
}
export interface WindowHeaderProps extends HTMLMotionProps<"div"> {
  disableHide?: boolean
  isExpanded?: boolean
  isMinimized?: boolean
  onClose?: () => void
  onExpand?: () => void
  onMinimize?: () => void
  size?: WindowHeaderSize
}
const WindowHeader = ({
  disableHide,
  isExpanded,
  isMinimized,
  onClose,
  onExpand,
  onMinimize,
  size = 1,
  ...props
}: WindowHeaderProps) => {
  const [hover, setHover] = useState(Boolean(disableHide))
  return (
    <Header {...props} size={size}>
      <Actions
        className={hover ? undefined : "hidden"}
        onMouseEnter={disableHide ? undefined : () => setHover(true)}
        onMouseLeave={disableHide ? undefined : () => setHover(false)}
      >
        {onClose && (
          <Dot
            aria-label="Close"
            color="red"
            icon={faTimes}
            iconProps={{ size: iconSize[size] }}
            onClick={onClose}
            size={size}
          />
        )}
        {onMinimize && (
          <Dot
            aria-label="Minimize"
            color="yellow"
            icon={faWindowMinimize}
            iconProps={{
              rotation: isMinimized ? 180 : undefined,
              size: iconSize[size],
              style: isMinimized
                ? { marginTop: spacing.spacing1 }
                : { marginBottom: spacing.spacing1 },
            }}
            onClick={onMinimize}
            size={size}
          />
        )}
        {onExpand && (
          <Dot
            aria-label="Full Screen"
            color="green"
            icon={isExpanded ? faCompressAlt : faExpandAlt}
            iconProps={{ size: iconSize[size] }}
            onClick={onExpand}
            size={size}
          />
        )}
      </Actions>
    </Header>
  )
}

export default WindowHeader
