import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ColorsList from "./ColorsList"
import media from "style/media"
import Info from "./Info"

const Root = styled.header`
  background-color: ${props => props.theme.colors.primary700};
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing6};
  padding: 1.45rem 1.0875rem;
`

const StyledHeading = styled.h1`
  flex: 1 1 auto;
  margin: 0;
  margin-right: ${props => props.theme.spacing2};
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props => media.lessThan("medium")`
    font-size: ${props.theme.font.size20};
  `}
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`

interface HeaderProps {
  keywords?: string
  title?: string
}
const Header = ({ keywords, title }: HeaderProps) => {
  if (!keywords) return null
  if (!title) return null
  return (
    <Root>
      <StyledHeading>
        <StyledLink to="/">
          <Info keywords={keywords} title={title} />
        </StyledLink>
      </StyledHeading>
      <ColorsList />
    </Root>
  )
}

export default Header
