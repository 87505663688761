import styled from "styled-components"
import WindowHeader from "./WindowHeader"

const StyledHeader = styled(WindowHeader)`
  position: fixed;
  width: 500px;
  right: 0;
  bottom: 0;
`

export default StyledHeader
