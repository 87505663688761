import React from "react"
import styled, { css } from "styled-components"

const overflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Title = styled.div`
  ${overflow}
  font-size: 1.1em;
`

const SubTitle = styled.em`
  ${overflow}
  font-weight: ${props => props.theme.font.weightRegular};
`

interface InfoProps {
  keywords: string
  title: string
}
const Info = ({ keywords, title }: InfoProps) => {
  const keywordsSplit = keywords.split(",").join(" / ")
  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{keywordsSplit}</SubTitle>
    </>
  )
}

export default Info
