import React, { useEffect, useRef } from "react"
import { AnimatePresence } from "framer-motion"
import DraggableWindow from "./DraggableWindow"
import ExpandedWindow from "./ExpandedWindow"
import MinimizedWindow from "./MinimizedWindow"

export interface DesktopWindowProps {
  children: React.ReactNode
  constraint: React.RefObject<Element>
  disableAnimationOnMount: boolean
  isExpanded: boolean
  isMinimized: boolean
  onClose: () => void
  onExpand: () => void
  onMinimize: () => void
}
const DesktopWindow = ({
  children,
  constraint,
  disableAnimationOnMount,
  isExpanded,
  isMinimized,
  onClose,
  onExpand,
  onMinimize,
}: DesktopWindowProps) => {
  const prev = useRef<boolean>()
  useEffect(() => {
    prev.current = isExpanded
  }, [isExpanded])

  let render: React.ReactNode = null
  if (isMinimized) {
    render = (
      <MinimizedWindow
        key="minimized"
        isExpanded={isExpanded}
        isMinimized
        onClose={onClose}
        onExpand={onExpand}
        onMinimize={onMinimize}
        initial={{
          opacity: 0,
          transitionDelay: "50ms",
          transitionDuration: "50ms",
        }}
        animate={{
          opacity: 1,
          transitionDelay: "0ms",
          transitionDuration: "50ms",
        }}
        exit={{ opacity: 0 }}
      />
    )
  } else if (isExpanded) {
    render = (
      <ExpandedWindow
        key="expanded"
        onClose={onClose}
        onExpand={onExpand}
        onMinimize={onMinimize}
      >
        {children}
      </ExpandedWindow>
    )
  } else {
    render = (
      <DraggableWindow
        key="draggable"
        constraint={constraint}
        disableAnimationOnMount={prev.current || disableAnimationOnMount}
        onClose={onClose}
        onExpand={onExpand}
        onMinimize={onMinimize}
      >
        {children}
      </DraggableWindow>
    )
  }

  return <AnimatePresence initial={false}>{render}</AnimatePresence>
}

export default DesktopWindow
