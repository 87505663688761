import React from "react"
import { breakpointsNum } from "style/media"
import useWindowDimensions from "hooks/useWindowDimensions"

export interface HiddenProps {
  children: React.ReactNode
  lgDown?: boolean
  lgUp?: boolean
  mdDown?: boolean
  mdUp?: boolean
  smDown?: boolean
  smUp?: boolean
  xlDown?: boolean
  xlUp?: boolean
}
const Hidden: React.FC<HiddenProps> = ({ children, ...props }: HiddenProps) => {
  const { width } = useWindowDimensions()
  if (props.lgDown && width <= breakpointsNum.large) return null
  if (props.lgUp && width >= breakpointsNum.large) return null

  if (props.mdDown && width <= breakpointsNum.medium) return null
  if (props.mdUp && width >= breakpointsNum.medium) return null

  if (props.smDown && width <= breakpointsNum.small) return null
  if (props.smUp && width >= breakpointsNum.small) return null

  if (props.xlDown && width <= breakpointsNum.huge) return null
  if (props.xlUp && width >= breakpointsNum.huge) return null

  return <>{children}</>
}

export default Hidden
