const spacing = {
  spacing1: "0.25rem",
  spacing2: "0.5rem",
  spacing3: "0.75rem",
  spacing4: "1rem",
  spacing5: "1.25rem",
  spacing6: "1.5rem",
  spacing8: "2rem",
  spacing10: "2.5rem",
  spacing12: "3rem",
}

export default spacing
