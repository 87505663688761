import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import WindowBody from "./WindowBody"
import WindowHeader from "./WindowHeader"
import WindowNav from "./WindowNav"

const Root = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

interface MobileWindowProps {
  children: React.ReactNode
  onClose: () => void
}
const MobileWindow = ({ children, onClose, ...props }: MobileWindowProps) => {
  return (
    <Root
      {...props}
      initial={{ x: "100%" }}
      animate={{
        x: "0%",
      }}
      exit={{
        x: "100%",
      }}
      transition={{ ease: "easeInOut", duration: 0.25 }}
    >
      <WindowHeader disableHide onClose={onClose} size={2} />
      <WindowNav />
      <WindowBody>{children}</WindowBody>
    </Root>
  )
}

export default MobileWindow
