import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import {
  motion,
  useMotionValue,
  useTransform,
  HTMLMotionProps,
} from "framer-motion"
import { breakpointsNum } from "style/media"
import WindowHeader from "./WindowHeader"
import WindowBody from "./WindowBody"
import WindowNav from "./WindowNav"
import { lsGet, lsSet } from "utils/localStorage"

const MAX_HEIGHT = 99.0
const MAX_WIDTH = 99.0
const MIN_HEIGHT = 250.0
const MIN_WIDTH = breakpointsNum.small

const Root = styled(motion.div)`
  border-radius: ${props => props.theme.border.radius};
  border: 1px solid ${props => props.theme.colors.neutral700};
  overflow: hidden;
  position: fixed;

  display: flex;
  flex-direction: column;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const Resize = styled(motion.div)`
  cursor: nwse-resize;
  position: absolute;
  width: 1em;
  height: 1em;
  bottom: 0;
  right: 0;
  user-select: none;
`

interface DraggableWindowProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode
  constraint: React.RefObject<Element>
  disableAnimationOnMount: boolean
  onClose: () => void
  onExpand: () => void
  onMinimize: () => void
}
const DraggableWindow = ({
  children,
  constraint,
  disableAnimationOnMount,
  onClose,
  onExpand,
  onMinimize,
  ...props
}: DraggableWindowProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [, setForce] = useState({})
  const dragX = useMotionValue(0)
  const dragY = useMotionValue(0)
  const resizeX = useMotionValue(0)
  const resizeY = useMotionValue(0)

  const width = useTransform(
    resizeX,
    value => `${Math.min(MAX_WIDTH, (100.0 * value) / window.innerWidth)}%`
  )
  const height = useTransform(
    resizeY,
    value => `${Math.min(MAX_HEIGHT, (100.0 * value) / window.innerHeight)}%`
  )

  const force = useCallback(() => setForce({}), [])

  useLayoutEffect(() => {
    const dX = lsGet("dragX") ?? window.innerWidth * 0.1
    const dY = lsGet("dragY") ?? window.innerHeight * 0.1
    dragX.set(parseInt(`${dX}`))
    dragY.set(parseInt(`${dY}`))

    const rX = lsGet("resizeX") ?? window.innerWidth * 0.75
    const rY = lsGet("resizeY") ?? window.innerHeight * 0.75
    resizeX.set(parseInt(`${rX}`))
    resizeY.set(parseInt(`${rY}`))
  }, [])

  useLayoutEffect(() => {
    force()
  }, [force])

  useEffect(() => {
    function handleResize() {
      const newWidth = Math.min(
        MAX_WIDTH * window.innerWidth,
        Math.max(
          MIN_WIDTH,
          (parseFloat(width.get()) * window.innerWidth) / 100.0
        )
      )

      const newHeight = Math.min(
        MAX_HEIGHT * window.innerHeight,
        Math.max(
          MIN_HEIGHT,
          (parseFloat(height.get()) * window.innerHeight) / 100.0
        )
      )
      resizeX.set(newWidth)
      resizeY.set(newHeight)
      lsSet("resizeX", `${newWidth}`)
      lsSet("resizeY", `${newHeight}`)
      force()
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })
  return (
    <Root
      ref={rootRef}
      {...props}
      initial={disableAnimationOnMount ? false : { scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        left: dragX,
        top: dragY,
        width,
        height,
        transformOrigin: rootRef.current
          ? `${window.innerWidth - dragX.get() - 100}px ${
              window.innerHeight - dragY.get()
            }px`
          : undefined,
      }}
    >
      <WindowHeader
        isExpanded={false}
        isMinimized={false}
        onClose={onClose}
        onExpand={onExpand}
        onMinimize={onMinimize}
        drag
        _dragValueX={dragX}
        _dragValueY={dragY}
        dragConstraints={constraint}
        dragMomentum={false}
        onDragEnd={(_e, info) => {
          const { x, y } = info.point
          lsSet("dragX", `${x}`)
          lsSet("dragY", `${y}`)
          force()
        }}
      />
      <WindowNav />
      <WindowBody>{children}</WindowBody>
      <Resize
        drag
        _dragValueX={resizeX}
        _dragValueY={resizeY}
        dragMomentum={false}
        dragConstraints={{
          left: MIN_WIDTH,
          top: MIN_HEIGHT,
        }}
        onDragEnd={(_e, info) => {
          const { x, y } = info.point
          lsSet("resizeX", `${x}`)
          lsSet("resizeY", `${y}`)
          force()
        }}
      />
    </Root>
  )
}

export default DraggableWindow
