/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import Layout from "./src/components/Layout"
import Provider from "./src/Provider"

export const wrapRootElement = ({ element }) => (
  <Provider>
    <Layout>{element}</Layout>
  </Provider>
)
