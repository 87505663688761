import { rgba } from "polished"
import styled from "styled-components"

const Body = styled.div`
  background-color: ${props => rgba(props.theme.colors.neutral900, 1)};
  flex-grow: 1;
  overflow: auto;
  padding: ${props => props.theme.spacing4} ${props => props.theme.spacing8};
  padding-bottom: ${props => props.theme.spacing6};

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => rgba(props.theme.colors.primary800, 0.5)};
  }

  &::-webkit-scrollbar-corner {
    background-color: ${props => rgba(props.theme.colors.neutral800, 0.5)};
  }
`

export default Body
