import React from "react"
import styled from "styled-components"
import Icon, { IconProps } from "components/Icon"

const A = styled.a`
  color: ${props => props.theme.colors.primary050};
  transition-duration: 150ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:link,
  &:visited,
  &:active {
    color: ${props => props.theme.colors.primary050};
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.theme.colors.primary500};
  }
`

interface LinkIconProps {
  icon: IconProps["icon"]
  url: string
}
const LinkIcon: React.FC<LinkIconProps> = ({ icon, url }) => (
  <A href={url} target="_blank">
    <Icon icon={icon} size="6x" />
  </A>
)
export default LinkIcon
