import React, { useState, HTMLAttributes } from "react"
import styled, { DefaultTheme } from "styled-components"
import Icon, { IconProps } from "components/Icon"

const StyledDot = styled.button<{ color: DotColor }>`
  background-color: ${props => colors[props.color](props.theme).background};
  border: none;
  border-radius: 50%;
  color: ${props => colors[props.color](props.theme).color};
  cursor: pointer;
  display: inline-block;
  padding: 0;
  position: relative;
  transition-duration: 150ms;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    background-color: ${props => colors[props.color](props.theme).active};
  }

  &:focus {
    outline: none;
  }
`

export type DotColor = "green" | "red" | "yellow"
const colors: Record<
  DotColor,
  (theme: DefaultTheme) => { active: string; background: string; color: string }
> = {
  green: ({ colors }) => ({
    active: colors.green300,
    background: colors.green400,
    color: colors.green800,
  }),
  red: ({ colors }) => ({
    active: colors.red300,
    background: colors.red400,
    color: colors.red800,
  }),
  yellow: ({ colors }) => ({
    active: colors.yellow300,
    background: colors.yellow400,
    color: colors.yellow800,
  }),
}

export interface DotProps extends HTMLAttributes<HTMLButtonElement> {
  color: DotColor
  icon: IconProps["icon"]
  iconProps?: Omit<IconProps, "icon">
  size?: number
}
const Dot = ({ color, icon, iconProps, size = 1, ...props }: DotProps) => {
  return (
    <StyledDot
      {...props}
      color={color}
      style={{
        height: `${size}rem`,
        width: `${size}rem`,
      }}
    >
      <Icon icon={icon} {...iconProps} />
    </StyledDot>
  )
}

export default Dot
